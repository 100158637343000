import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import { withAccount } from 'imports/core/api/accounts/accountContext';
import { withRouter } from 'next/router';
import nookies from 'nookies';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import styled from 'styled-components';
import { withResponsiveContext } from '/imports/core/api/responsiveContext';
import { withIntl } from '/imports/core/api/useIntl';
import { withTracking } from '/imports/core/hooks/useTracking';
import {
  FAKE_TEMPLATES,
  FEATURED_TEMPLATES,
  FEATURED_TEMPLATES_MESSAGES,
  TEMPLATES,
} from '/imports/generator/api/constants';
import { randomizeTemplates } from '/imports/generator/api/helpers';
import OnboardingSliderTemplateV1 from '/imports/onboarding/ui/atoms/OnboardingSliderTemplateV1';
import { BREAKPOINTS } from '/styles/variables';
import { withWindowSize } from 'imports/core/api/useWindowSize';

@withTracking
@withRouter
@withIntl
@withResponsiveContext
@withAccount
@withWindowSize
class OnboardingSliderV1 extends PureComponent {
  static propTypes = {
    onSelect: PropTypes.func.isRequired,
    isPopupOpen: PropTypes.bool,
  };

  state = {
    width: 0,
    height: 0,
    current: 0,
    templates: [],
    loading: true,
  };

  updateDimensions = () => {
    if (!this._wrapper) {
      return;
    }
    const top = this._wrapper.offsetTop;
    const wHeight = window.innerHeight;
    const footer = (window.innerWidth > BREAKPOINTS.md && 102) || 240;
    const height = Math.max(300, wHeight - top - 120 - footer);
    const width = (height / 280) * 190;
    this.setState({ width, height });
  };

  componentDidMount() {
    const { sessionStartHandler, trackEvent, host, locale, isMobile, width } = this.props;
    sessionStartHandler();
    let templates = this.shuffleTemplates();
    templates = this.filterTemplatesByRole(templates);
    let obj = {
      random_default_template: templates[0].name,
    };
    if (marvelEmitter.getActiveVariant('exp_new_template'))
      obj.exp_new_template = marvelEmitter.getActiveVariant('exp_new_template');

    if (marvelEmitter.getActiveVariant('exp_cali_lima')) {
      obj.exp_cali_lima = marvelEmitter.getActiveVariant('exp_cali_lima');
    }
    if (marvelEmitter.getActiveVariant('exp_ats_preview_v2')) {
      obj.exp_ats_preview_v2 = marvelEmitter.getActiveVariant('exp_ats_preview_v2');
    }

    obj.exp_job_track = marvelEmitter.getActiveVariant('job-tracking');
    obj.exp_template_budapest = marvelEmitter.getActiveVariant('exp_template_budapest');
    switch (locale) {
      case 'it':
        obj.exp_template_english_vs_italian = marvelEmitter.getActiveVariant('exp_template_english_vs_italian');
        break;
      case 'ja':
        obj.exp_template_english_vs_japanese = marvelEmitter.getActiveVariant('exp_template_english_vs_japanese');
        break;
      case 'zh-cn':
        obj.exp_template_english_vs_chinese = marvelEmitter.getActiveVariant('exp_template_english_vs_chinese');
        break;
      case 'da':
        obj.exp_template_english_vs_danish = marvelEmitter.getActiveVariant('exp_template_english_vs_danish');
        break;
      case 'et':
        obj.exp_template_english_vs_estonian = marvelEmitter.getActiveVariant('exp_template_english_vs_estonian');
        break;
      case 'sl':
        obj.exp_template_english_vs_slovenian = marvelEmitter.getActiveVariant('exp_template_english_vs_slovenian');
        break;
      case 'fi':
        obj.exp_template_english_vs_finnish = marvelEmitter.getActiveVariant('exp_template_english_vs_finnish');
        break;
    }

    trackEvent('template_view', obj);
    this.setState({
      templates,
    });

    window.addEventListener('resize', this.updateDimensions);

    try {
      document.getElementsByTagName('body')[0].classList.remove('_container');
    } catch (error) {
      console.error("Can't find body tag");
    }
    this.setState({ loading: false });
  }

  filterTemplatesByRole(templates) {
    const { agent_client_id } = nookies.get({});
    const { currentUser } = this.props;
    const isAgentOrAdmin = currentUser && (currentUser.role === 'agent' || currentUser.role === 'admin');
    if ((agent_client_id !== undefined && agent_client_id !== null) || isAgentOrAdmin) {
      return templates;
    } else {
      return templates.filter(
        (template) => (template.id !== 'atsresume' || template.name === 'Tokyo') && template.id !== 'budapest-v2',
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentUser !== this.props.currentUser) {
      const templates = this.shuffleTemplates();
      const filteredTemplates = this.filterTemplatesByRole(templates);
      this.setState({
        templates: filteredTemplates,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
    try {
      document.getElementsByTagName('body')[0].classList.remove('_container');
    } catch (error) {
      console.error("Can't find body tag");
    }
  }

  shuffleTemplates() {
    const visibleTemplates = TEMPLATES.filter((template) => !template.hidden);
    const featured_experiment_templates = FEATURED_TEMPLATES;
    const activeBruneiExp = marvelEmitter.getActiveVariant('exp_new_template') === 'with_brunei';
    const { locale, isMobile, currentUser } = this.props;

    return randomizeTemplates(
      visibleTemplates,
      FAKE_TEMPLATES,
      featured_experiment_templates.slice(0, 2),
      featured_experiment_templates.slice(2),
    ).filter((template) => {
      if (typeof template.hiddenOnSlider == 'function') {
        return !template.hiddenOnSlider({
          locale,
          activeBruneiExp,
          isMobile,
          currentUser,
        });
      }
      return !template.hiddenOnSlider;
    });
  }

  getWrapperRef = (node) => {
    this._wrapper = node;
    this.updateDimensions();
  };

  getSlider = (node) => {
    this._slider = node;
  };

  next = () => {
    this._slider.slickNext();
  };

  previous = () => {
    this._slider.slickPrev();
  };

  onTemplateSelect = (index) => {
    this.setState({ current: index });
  };

  render() {
    const { onSelect, isPopupOpen, locale } = this.props;
    if (this.state.loading) return null;
    const settings = {
      dots: false,
      arrows: false,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      centerMode: true,
      variableWidth: true,
      focusOnSelect: true,
      beforeChange: (current, next) => this.setState({ current: next }),
    };
    const { templates } = this.state;
    const featured_experiment_templates = FEATURED_TEMPLATES;
    return (
      <SliderWrapper ref={this.getWrapperRef}>
        <Slider ref={this.getSlider} {...settings}>
          {templates.map((template, i) => {
            if (locale !== 'en' && template.name === 'Tokyo') return;
            const featured = !template.hideLabel && featured_experiment_templates.includes(template.id);
            let message = '';
            if (featured) {
              switch (i) {
                case 0:
                  message = FEATURED_TEMPLATES_MESSAGES[0];
                  break;

                case 1:
                  message = FEATURED_TEMPLATES_MESSAGES[1];
                  break;

                case templates.length - 1:
                  message = FEATURED_TEMPLATES_MESSAGES[2];
                  break;

                default:
                  break;
              }
            }
            return (
              <OnboardingSliderTemplateV1
                selected={i === this.state.current}
                key={`${template.image || template.id}`}
                {...template}
                {...this.state}
                featured={featured}
                message={message}
                onSelect={onSelect}
                onTemplateSelect={this.onTemplateSelect}
              />
            );
          })}
        </Slider>
      </SliderWrapper>
    );
  }
}

const SliderWrapper = styled.section`
  text-align: center;
  position: relative;
  margin-top: 65px;
  .slick-slide > div {
    padding: 0 16px;
  }

  ${({ theme }) => theme.max('md')`
    margin: 0 -20px;
  `}
  ${({ theme }) => theme.min('md')`
    .slick-list {
      overflow: initial;
    }
  `}


  & .slick-list {
    overflow: initial;
  }

  ${({ theme }) => theme.max('md')`
      padding: 0 0 20px;
    `}
`;

export default OnboardingSliderV1;
