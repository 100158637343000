import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-apollo';
import { useFormContext } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { useAccount } from '/imports/core/api/accounts/accountContext';
import { ERROR_MESSAGES } from '/imports/core/api/constants';
import { useGeneralDesign } from '/imports/core/api/generalContext';
import { useResponsive } from '/imports/core/api/responsiveContext';
import useIntl from '/imports/core/api/useIntl';
import useWindowSize from '/imports/core/api/useWindowSize';
import useTracking from '/imports/core/hooks/useTracking';
import { UPDATE_RESUME_DETAIL } from '/imports/generator/api/apollo/client/mutations';
import WizardHeader from '/imports/generator/ui/atoms/WizardHeader';
import WizardSubHeader from '/imports/generator/ui/atoms/WizardSubHeader';
import WizardStartForm from '/imports/generator/ui/components/WizardStartForm';
import WizardLayout from '/imports/generator/ui/layouts/WizardLayout';
import { SET_USER_DETAILS } from '/imports/onboarding/api/apollo/client/mutations';
import { isWithClickToEditVariant } from '/imports/pdf/core/api/helpers';
import { getGender, getReMarketingConv, isRemarketingHost } from '/lib/helpers';

const WizardStart = ({ resume, updateImmue }) => {
  const { currentUser } = useAccount();
  const {
    query: { step, targetField },
  } = useRouter();
  const currentDetails = currentUser || {};
  const {
    details: { lastName, firstName, email = '', phone, city, address, postalCode, showDisclaimer },
  } = resume;

  const {
    setError: setErrorForm,

    formState: { isValid, errors },
    setValue,
  } = useFormContext();

  const [emailNotValid, setEmailNotValid] = useState(false);
  const [isEmailDuplicate, setIsEmailDuplicate] = useState(false);
  const { host, breakpoint, isGeneratorMobileView, isMobile } = useResponsive();
  const [setUserDetails] = useMutation(SET_USER_DETAILS);
  const [updateSetting] = useMutation(UPDATE_RESUME_DETAIL);
  const { width } = useWindowSize();
  const { trackEvent, trackUserIdentity } = useTracking();
  const { t, locale } = useIntl();
  const generalData = useGeneralDesign();

  const [show, setShow] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const firstInput = document.querySelector('input');
    window.localStorage.removeItem('disable-tags-repositionning');
    Object.keys(resume.details).forEach((key) => setValue(key, resume.details[key]));
    setShow(true);
    setTimeout(() => setShow(false), 3000);
    const withoutDefaultFocus = isWithClickToEditVariant(step) && targetField && !isMobile;
    if (firstInput && !withoutDefaultFocus) firstInput.focus();
    let obj = {
      fromUpload: resume.fromScan?.toString() ?? 'false',
      resume_language: resume.settings?.language ?? locale,
    };

    const isMobileRepositioning = ['xs', 'sm'].includes(breakpoint);
    if (marvelEmitter.getActiveVariant('exp_ideas_suggestion_prompt')) {
      obj.exp_ideas_suggestion_prompt = marvelEmitter.getActiveVariant('exp_ideas_suggestion_prompt');
    }
    if (marvelEmitter.getActiveVariant('exp_add_block_step') && locale == 'ar') {
      obj.exp_add_block_step = marvelEmitter.getActiveVariant('exp_add_block_step');
    }
    if (marvelEmitter.getActiveVariant('exp_block_repositing') && !isMobileRepositioning) {
      obj.exp_block_repositing = marvelEmitter.getActiveVariant('exp_block_repositing');
    }
    if (marvelEmitter.getActiveVariant('exp_heading_start_title') && locale == 'fr') {
      obj.exp_heading_start_title = marvelEmitter.getActiveVariant('exp_heading_start_title');
    }
    if (marvelEmitter.getActiveVariant('exp_resumes_writing')) {
      obj.exp_resumes_writing = marvelEmitter.getActiveVariant('exp_resumes_writing');
    }
    if (marvelEmitter.getActiveVariant('exp_brunei_design')) {
      obj.exp_brunei_design = marvelEmitter.getActiveVariant('exp_brunei_design');
    }

    if (marvelEmitter.getActiveVariant('exp_ats_resume_design')) {
      obj.exp_ats_resume_design = marvelEmitter.getActiveVariant('exp_ats_resume_design');
    }

    trackEvent('heading_view', obj);
    if (width > 800) trackEvent('generator_preview_view');

    if (window && window.gtag && isRemarketingHost(host)) {
      const remarketingData = getReMarketingConv(host);
      window.gtag('event', 'conversion', {
        send_to: `${remarketingData.id}/${remarketingData.heading}`,
        aw_remarketing_only: true,
      });
    }
    try {
      document.getElementsByTagName('body')[0].classList.remove('_container');
    } catch (error) {
      console.error("Can't find body tag");
    }
  }, []);

  const onSubmit = async () => {
    const origin = localStorage.getItem('resumedone:campaign-origin');

    let gender;
    if (currentDetails && !currentDetails.gender) {
      localStorage.setItem('redneg-hack', 'active');
      let campaignGender = localStorage.getItem('resumedone:campaign-redneg');
      gender = await getGender(currentDetails.firstName || firstName);

      // NOTE: gender api returns null string
      if ((!gender || gender === 'null') && campaignGender) {
        // campaign gender is saved in reverse order so make it reverse again
        campaignGender = campaignGender.split('').reverse().join('');
        if (['male', 'female'].includes(campaignGender.toLowerCase())) {
          gender = campaignGender;
        }
      }
    }

    try {
      const params = {
        firstName: currentDetails.firstName || firstName,
        lastName: currentDetails.lastName || lastName,
        email: (currentDetails.email || email)?.toLowerCase(),
        phone: currentDetails.phone || phone,
        address: currentDetails.address || address,
        postalCode: currentDetails.postalCode || postalCode,
        city: currentDetails.city || city,
        showDisclaimer: showDisclaimer || !origin,
        primaryLanguage: locale,
      };
      params.gender = gender || 'male';
      const colorShouldBeGirlBlue =
        gender == 'female' && resume.settings.template == 'budapest' && resume.settings.color == 'black';
      if (colorShouldBeGirlBlue) {
        const options = {
          variables: {
            docId: resume.id,
            path: 'settings.color',
            value: 'budapestGirlBlue',
          },
        };
        await updateSetting(options);
      }

      localStorage.setItem('email', params.email);
      const {
        data: {
          setUserDetails: { user },
        },
      } = await setUserDetails({
        variables: params,
      });
      trackUserIdentity(user, true, { createdAt: user.createdAt }, false);
    } catch (e) {
      const { networkError, graphQLErrors, message = '' } = e;
      let errorMessage = message;

      if (networkError) errorMessage = networkError.message;

      if (graphQLErrors) errorMessage = graphQLErrors.message;

      if (graphQLErrors && message.includes('email')) {
        if (message.includes('email already exists')) {
          setErrorForm('email', { type: 'isEmailDuplicate', message: ERROR_MESSAGES.isEmailDuplicate });
          setIsEmailDuplicate(true);
        } else {
          setErrorForm('email', { type: 'emailNotValid ', message: ERROR_MESSAGES.emailNotValid });
          setEmailNotValid(true);
        }
      }
      setError(errorMessage);
      return false;
    }

    localStorage.removeItem('resumedone:facebook-campaign-g');
    return true;
  };
  return (
    <WizardLayout
      isFormValid={isValid}
      onSubmit={onSubmit}
      resume={resume}
      hasError={emailNotValid || isEmailDuplicate || error}
      padded
      updateImmue={updateImmue}
    >
      <WizardHeader designV2={generalData.designV2} padded>
        <div dangerouslySetInnerHTML={{ __html: t('generator.heading.title') }} />
      </WizardHeader>
      <StyledWizardSubHeader>{t('generator.heading.info')}</StyledWizardSubHeader>
      <WizardStartForm resume={resume} currentUser={currentUser} updateImmue={updateImmue} />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </WizardLayout>
  );
};

WizardStart.propTypes = {
  setUserDetails: PropTypes.func,
  resume: PropTypes.object,
};

const StyledWizardSubHeader = styled(WizardSubHeader)`
  ${(p) => p.theme.max('xs')`
   padding: 0 18px;
  `}
  ${({ isModal, theme }) =>
    isModal &&
    css`
      display: flex;
      align-items: center;
      gap: 30px;
      ${theme.max('xs')`
        flex-direction: column;
        gap:15px;
        margin-bottom: 30px;
        align-items: baseline;
      `}
    `}
`;

const ErrorMessage = styled.span`
  font-size: 14px;
  color: ${(p) => p.theme.colors.danger};
`;

export default WizardStart;
