import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import last from 'lodash/last';
import { withRouter } from 'next/router';
import nookies from 'nookies';
import PropTypes from 'prop-types';
import qs from 'qs';
import { PureComponent, Fragment } from 'react';
import styled, { css } from 'styled-components';

import Flex from 'imports/core/ui/atoms/Flex';
import { RTLLanguages } from 'imports/generator/api/constants';
import {
  getBruneiHeaderTitleLines,
  getBruneiHeaderNameLines,
  getSABudapestResumeSettingsMap,
  isBruneiNewDesign,
  isAtsNewDesign,
  isBudapestNewDesignActive,
  isDesignWithIconSocialLink,
  isDesignWithSocialLink,
} from 'imports/generator/api/helpers';
import PDFViewer from 'imports/pdf/core/ui/components/PDFViewer';
import { withAccount } from '/imports/core/api/accounts/accountContext';
import { withResponsiveContext } from '/imports/core/api/responsiveContext';
import { withIntl } from '/imports/core/api/useIntl';
import { withDomain } from '/imports/core/hooks/useDomain';
import { withTracking } from '/imports/core/hooks/useTracking';
import Button from '/imports/core/ui/atoms/Button';
import SvgIcon from '/imports/core/ui/atoms/SvgIcon';
import { DocxIcon, MailIcon, PdfIcon } from '/imports/generator/ui/assets';
import DocxModel from '/imports/generator/ui/components/DocxModel';
import EmailDownloadModal from '/imports/generator/ui/components/EmailDownloadModal';
import EmailNotifiedModal from '/imports/generator/ui/components/EmailNotifiedModal';
import {
  cookieParser,
  exportDocx,
  exportEmailHTML,
  exportHTMLString,
  getDefaultLanguage,
  getRoute,
  isBlogDomain,
} from '/lib/helpers';

// Map of Docx templates that are enabled for an environment.
const DocxTemplateEnabledMap = {
  budapest: true,
  riga: true,
  chicago: true,
  perth: true,
  shanghai: true,
  vladivostok: true,
  prague: true,
  sydney: true,
  montecarlo: true,
  kiev: true,
  sf: true,
  rotterdam: true,
};

@withTracking
@withDomain
@withAccount
@withResponsiveContext
@withRouter
@withIntl
class DownloadOptionsDropdown extends PureComponent {
  static propTypes = {
    source: PropTypes.object,
    isHorizontal: PropTypes.bool,
    containerCustomCss: PropTypes.array,
    dropdownMenuCustomCss: PropTypes.array,
    small: PropTypes.bool,
    isCoverLetter: PropTypes.bool,
  };

  static defaultProps = {
    isCoverLetter: false,
  };

  state = {
    expanded: false,
    origin: null,
    documentHeight: null,
    model: false,
    emailModal: false,
    emailNotifiedModal: false,
    notification: null,
    variant: marvelEmitter.getActiveVariant('website_resume_experiment'),
    docxButtonLoading: false,
    resumeSource: null,
    resumehref: null,
    bruneiTitleLines: 0,
    bruneiNameLines: 0,
  };

  // Checks if the current document allows Docx export.
  get allowsDocxExport() {
    const { isCoverLetter, source: { settings = {} } = {} } = this.props;
    const { template, templateVersion } = settings;

    // TODO: refactor this logic as the Docx list grows
    const isEnabled = DocxTemplateEnabledMap[template];
    if (template == 'budapest' && (templateVersion == 2 || templateVersion == 3)) {
      return false;
    }
    if (!isCoverLetter && isEnabled) {
      return true;
    }
    return false;
  }

  get allowCreateWebsite() {
    const { isCoverLetter, source: { currentStep, slug } = {} } = this.props;
    const { variant } = this.state;
    return variant === 'website' && !isCoverLetter && currentStep === 'finish' && !slug;
  }

  componentDidMount() {
    this.setState({ origin: window.location.origin });
    window.addEventListener('click', this.handleWindowClick);
  }

  componentDidUpdate(prevProps, prevState) {
    const { expanded } = this.state;
    const { expanded: prevExpanded } = prevState;
    const { source } = this.props;
    const { source: prevSource } = prevProps;

    if (expanded && prevExpanded !== expanded) {
      const templateNode = document.getElementById('template-page');

      // For templates with coloured background left/right column, templateNode will return a node
      // Templates - [Budapest, SF, Amsterdam, Kiev]
      if (!templateNode) return;

      const { offsetHeight } = templateNode;
      return this.setState({
        documentHeight: offsetHeight,
      });
    }

    if (source && prevSource && source.settings.template !== prevSource.settings.template)
      this.setState({
        documentHeight: null,
      });

    if (
      this.state.emailModal &&
      prevState.emailModal !== this.state.emailModal &&
      source.settings.template === 'brunei' &&
      !!source.details.userPic
    ) {
      this.handleSetResumeSource();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleWindowClick);
  }

  getDocxExportLink() {
    const { source = {}, docxEndpoint, currentUser } = this.props;
    const getGender = currentUser?.gender === 'female' ? 'female' : false;
    return `${docxEndpoint}/resumes/${source.id}/${getGender}`;
  }

  handleWindowClick = () => {
    this.setState({ expanded: false });
  };

  onClick = (resumeFormat) => () => {
    const {
      router: { asPath },
      source: {
        settings: { template, language, color, headingFont, contentFont, templateVersion },
      },
      isCoverLetter,
      trackEvent,
    } = this.props;

    this.setState({
      expanded: false,
    });

    if (!isCoverLetter) {
      return trackEvent('download_cta', {
        context: last(asPath.split('/')),
        template: `${template}${templateVersion > 1 ? `-v${templateVersion}` : ''}`,
        cv_format: resumeFormat,
        resume_language: language,
        color,
        resume_headingFont: headingFont,
        resume_contentFont: contentFont,
      });
    }
    return trackEvent('cover_letter_download_cta', {
      template,
      format: resumeFormat,
    });
  };

  toggleExpanded = (e) => {
    e.stopPropagation();
    this.setState((state) => ({ expanded: !state.expanded }));
  };

  toggleModal = (e) => {
    this.setState((state) => ({ model: !state.model }));
  };

  toggleEmailModal = (e) => {
    e.stopPropagation();
    this.setState((state) => ({ emailModal: !state.emailModal, expanded: false }));
  };

  toggleEmailNotifiedModal = (e) => {
    e.stopPropagation();
    if (this.state.notification !== 'loading')
      this.setState((state) => ({ emailNotifiedModal: !state.emailNotifiedModal }));
  };

  onClickDownload = async () => {
    if (this.state.docxButtonLoading) return;
    this.setState({ docxButtonLoading: true });
    const { token, agent_client_id } = cookieParser();
    const blob = await exportDocx({ token, link: this.getDocxExportLink(), userId: agent_client_id });
    const newBlob = new Blob([blob]);
    const downloadURL = window.URL.createObjectURL(newBlob);
    const link = document.createElement('a');
    link.href = downloadURL;
    link.download = 'resume.docx';
    link.click();
    this.setState((state) => ({ model: !state.model }));
    this.onClick('docx')();
    this.setState({ docxButtonLoading: false });
  };

  handleSetResumeSource = () => {
    const { source } = this.props;
    this.setState({ resumeSource: source });
  };

  sendEmail = async (email, onePage, isRTL) => {
    const {
      source,
      currentUser: { gender, id },
      isCoverLetter,
      host,
    } = this.props;
    const { documentHeight, bruneiTitleLines, bruneiNameLines } = this.state;
    this.setState({ emailModal: false, emailNotifiedModal: true, notification: 'loading' });
    const { token } = cookieParser();
    const isBudapestTemplate = source.settings.template === 'budapest';
    const isBrunei = source.settings.template === 'brunei';
    const isBruneiImage = !!source?.details?.userPic;
    const isSwapable = RTLLanguages.includes(source.settings.language);
    const isDesignWithIconSocialLink = marvelEmitter.getActiveVariant('exp_social_link_logos');
    const atsNewDesign = isAtsNewDesign(
      this.props.currentUser,
      this.props.agent_client_id,
      source.settings.template === 'atsresume',
    );
    const exportObj = {
      source,
      token,
      onePage,
      redneg: gender,
      fromBuilder: true,
      isRTL,
      isSwapable,
      isAtsNewDesign: atsNewDesign,
      isDesignWithIconSocialLink,
    };
    if (isBudapestTemplate && !isCoverLetter) {
      exportObj.budapest_v2_tags_spacing = true;
      exportObj.middleContentLayout = source.saDetails.middleContentLayout;
      exportObj.mainColumnSize = source.saDetails.mainColumnSize;
      exportObj.nameSize = source.saDetails.nameSize;
      exportObj.titleSize = source.saDetails.titleSize;
      exportObj.paragraphSpacing = source.saDetails.paragraphSpacing;
      exportObj.generalFontSize = source.saDetails.generalFontSize;
      exportObj.sideBarContentLayout = source.saDetails.sideBarContentLayout;
      exportObj.userId = id;
      exportObj.resumeId = source.id;
    }
    if (isBrunei) {
      exportObj.isBruneiNewDesign = true;
      if (isBruneiImage) exportObj.bruneiHeaderTitleLines = bruneiTitleLines;
      if (isBruneiImage) exportObj.bruneiHeaderNameLines = bruneiNameLines;
    }
    if (documentHeight) exportObj.height = documentHeight.toString();

    const html = await exportHTMLString(exportObj, onePage, host, token);
    const options = {
      onePage,
    };

    const { template, color } = source.settings;

    const response = await exportEmailHTML({
      html,
      token,
      options,
      email,
      template,
      color,
      gender,
      isCoverLetter,
      isRTL,
      userId: id,
      resumeId: source.id,
    });
    if (response.status === 202) {
      this.onClick('sent_by_mail')();
    }
    this.setState({ notification: true ? 'success' : 'failure' });
  };

  clickHandler = (href, resumeFormat) => {
    const {
      source,
      router: { asPath },
      source: {
        settings: { template, language, color, headingFont, contentFont, templateVersion },
      },
      trackEvent,
    } = this.props;

    this.setState({
      expanded: false,
    });

    trackEvent('download_cta', {
      context: last(asPath.split('/')),
      template: `${template}${templateVersion > 1 ? `-v${templateVersion}` : ''}`,
      cv_format: resumeFormat,
      resume_language: language,
      color,
      resume_headingFont: headingFont,
      resume_contentFont: contentFont,
    });

    this.setState({ resumeSource: source, resumehref: href });
  };

  updateQueryString = (url, param, newValue) => {
    const regex = new RegExp(`([?&])${param}=([^&]*)`);
    if (url.match(regex)) {
      return url.replace(regex, `$1${param}=${newValue}`);
    } else {
      const separator = url.includes('?') ? '&' : '?';
      return url + separator + param + '=' + newValue;
    }
  };

  handleSetBruneiLines = (bruneiTitleLine, bruneiNameLine) => {
    const { resumehref, emailModal } = this.state;

    if (emailModal) {
      this.setState({ bruneiTitleLines: bruneiTitleLine, bruneiNameLines: bruneiNameLine });
    } else {
      let href = resumehref;
      if (href.includes('bruneiHeaderTitleLines') && href.includes('bruneiHeaderNameLines')) {
        href = this.updateQueryString(href, 'bruneiHeaderTitleLines', bruneiTitleLine);
        href = this.updateQueryString(href, 'bruneiHeaderNameLines', bruneiNameLine);
      } else if (href.includes('?')) {
        href = `${href}&bruneiHeaderTitleLines=${bruneiTitleLine}&bruneiHeaderNameLines=${bruneiNameLine}`;
      } else {
        href = `${href}?bruneiHeaderTitleLines=${bruneiTitleLine}&bruneiHeaderNameLines=${bruneiNameLine}`;
      }
      window.open(href, '_blank');
    }
    this.setState({ resumeSource: null });
  };

  render() {
    const {
      expanded,
      origin,
      documentHeight,
      model,
      emailModal,
      emailNotifiedModal,
      notification,
      docxButtonLoading,
      resumeSource,
    } = this.state;
    const { agent_client_id } = nookies.get({});
    const {
      source: { id, settings: { language } = {} } = {},
      source,
      isHorizontal,
      containerCustomCss,
      dropdownMenuCustomCss,
      small,
      isCoverLetter,
      locale,
      t,
      isMobile,
      router: { pathname },
      currentUser,
      host,
      preview,
    } = this.props;

    const {
      source: {
        settings: { template },
      },
    } = this.props;
    const isBrunei = template === 'brunei';
    const isAts = template === 'atsresume';
    const showIsBruneiHeaderLines = isBrunei && !!source?.details?.userPic;
    const queryString = {
      type: 'pdf',
      isDesignWithSocialLink: isDesignWithSocialLink(currentUser, agent_client_id),
      isDesignWithIconSocialLink: isDesignWithIconSocialLink(currentUser, agent_client_id),
      isBudapestDesignActive: isBudapestNewDesignActive(currentUser, agent_client_id),
      ...getSABudapestResumeSettingsMap(source, currentUser, agent_client_id),
      userId: currentUser && currentUser?.id,
      isBruneiNewDesign: isBruneiNewDesign(currentUser, agent_client_id, isBrunei),
      isAtsNewDesign: isAtsNewDesign(currentUser, agent_client_id, isAts),
      ...(showIsBruneiHeaderLines && {
        bruneiHeaderTitleLines: getBruneiHeaderTitleLines(source),
        bruneiHeaderNameLines: getBruneiHeaderNameLines(source),
      }),
    };

    if (documentHeight) {
      queryString.documentHeight = documentHeight;
    }
    queryString.locale = locale || getDefaultLanguage(host);
    queryString.redneg = currentUser?.gender || 'male';
    const path = isCoverLetter ? 'cover-letter' : 'resume';
    const url = `${origin}${getRoute(`/export/${path}/${id}?`, locale, false, host, isBlogDomain(host))}`;
    const isRTL = RTLLanguages.includes(language);
    const pdfHref = `${url}${qs.stringify({ ...queryString, cvFormat: 'pdf/A4' })}${isCoverLetter ? (isRTL && template !== 'budapest' ? '&isSwapable=true' : '') : isRTL ? '&isSwapable=true' : ''}`;
    queryString.onePage = true;
    const onePagePdfHref = `${url}${qs.stringify({ ...queryString, cvFormat: 'pdf' })}${isCoverLetter ? (isRTL && template !== 'budapest' ? '&isSwapable=true' : '') : isRTL ? '&isSwapable=true' : ''}`;
    const ButtonComponent = isHorizontal ? MoreButtonHorizontal : MoreButton;
    const qsWebsite = {
      source: id,
    };
    return (
      <Fragment>
        {resumeSource && (
          <Wrappper>
            <Hiddenblock>
              <PDFViewer
                source={resumeSource}
                width={928}
                updateCount={resumeSource.updatesCount}
                resumeSource={resumeSource}
                handleSetBruneiLines={(bruneiTitleLine, bruneiNameLine) =>
                  this.handleSetBruneiLines(bruneiTitleLine, bruneiNameLine)
                }
              />
            </Hiddenblock>
          </Wrappper>
        )}
        <Container containerCustomCss={containerCustomCss} data-testid="download-option">
          <ButtonComponent onClick={this.toggleExpanded}>
            <SvgIcon.More width="19px" height="6px" viewBox="0 0 16.8 4" />
            {isHorizontal && <TitleWrapper>{t('more')}</TitleWrapper>}
          </ButtonComponent>
          <DropdownMenu
            expanded={expanded}
            onClick={(e) => e.stopPropagation()}
            dropdownMenuCustomCss={dropdownMenuCustomCss}
          >
            <DropdownMenuInner>
              {this.allowsDocxExport && (
                <StyledLink onClick={this.toggleModal}>
                  <SvgContainer>
                    <DocxIcon />
                  </SvgContainer>
                  <StyledSpan>{t('header.download_docx')}</StyledSpan>
                </StyledLink>
              )}
              {preview || isCoverLetter || !showIsBruneiHeaderLines ? (
                <Fragment>
                  <StyledLink href={onePagePdfHref} target="_blank" onClick={this.onClick('pdf')} passHref>
                    <SvgContainer>
                      <PdfIcon viewBox={small ? '0 0 15 25' : '0 0 18 23'} />
                    </SvgContainer>
                    {t('dashboard.export_1_page_pdf')}
                  </StyledLink>
                  <StyledLink href={pdfHref} target="_blank" onClick={this.onClick('pdf/A4')} passHref>
                    <SvgContainer>
                      <PdfIcon viewBox={small ? '0 0 15 25' : '0 0 18 23'} />
                    </SvgContainer>
                    {t('dashboard.export_a4_pdf')}
                  </StyledLink>
                </Fragment>
              ) : (
                <Fragment>
                  <StyledButton onClick={() => this.clickHandler(onePagePdfHref, 'pdf')}>
                    <SvgContainer>
                      <PdfIcon viewBox={small ? '0 0 15 25' : '0 0 18 23'} />
                    </SvgContainer>{' '}
                    {t('dashboard.export_1_page_pdf')}
                  </StyledButton>
                  <StyledButton onClick={() => this.clickHandler(pdfHref, 'pdf/A4')}>
                    <SvgContainer>
                      <PdfIcon viewBox={small ? '0 0 15 25' : '0 0 18 23'} />
                    </SvgContainer>{' '}
                    {t('dashboard.export_a4_pdf')}
                  </StyledButton>
                </Fragment>
              )}
              {isMobile && pathname !== '/dashboard' && (
                <StyledLink onClick={this.toggleEmailModal}>
                  <SvgContainer>
                    <MailIcon />
                  </SvgContainer>
                  {t('header.export_email')}
                </StyledLink>
              )}
            </DropdownMenuInner>
          </DropdownMenu>
          <DocxModel
            toggleModal={this.toggleModal}
            onClickDownload={this.onClickDownload}
            open={model}
            link={this.getDocxExportLink()}
            loading={docxButtonLoading}
          />
          <EmailDownloadModal
            toggleModal={this.toggleEmailModal}
            sendEmail={this.sendEmail}
            email={currentUser ? currentUser.email : ''}
            open={emailModal}
            source={source}
            isRTL={isCoverLetter ? (isRTL && template !== 'budapest' ? true : false) : isRTL ? true : false}
          />
          <EmailNotifiedModal
            toggleModal={this.toggleEmailNotifiedModal}
            open={emailNotifiedModal}
            notification={notification}
          />
        </Container>
      </Fragment>
    );
  }
}

const StyledSpan = styled.span`
  display: inline-block;
  flex-grow: 1;
`;

const Container = styled.div`
  margin-left: 10px;
  z-index: 10;
  position: relative;
  ${(props) => props.containerCustomCss};
`;

const MoreButton = styled(Button)`
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0.6em;
  background-color: ${(p) => p.theme.colors.ctaPreview};
  transition:
    background-color 0.2s ease-in-out,
    border-color 0.2s ease-in-out;

  &:hover {
    background-color: ${(p) => p.theme.colors.ctaPreviewHover};
    border-color: ${(p) => p.theme.colors.ctaPreviewHover};
  }

  &:active {
    background-color: ${(p) => p.theme.colors.ctaPreviewClick};
    border-color: ${(p) => p.theme.colors.ctaPreviewClick};
  }
`;

const MoreButtonHorizontal = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  height: 100%;
  cursor: pointer;

  &:hover {
    color: #0087c1;
  }
`;

const TitleWrapper = styled.span`
  margin-left: 11px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      margin-right: 11px;
      margin-left: 0;
    `}
`;

const DropdownMenu = styled.div`
  position: absolute;
  right: 0;
  top: 58px;
  width: 220px;
  transform-origin: top;
  transform: scaleY(0);
  opacity: 0;
  padding: 0 !important;
  background-color: transparent;

  ${(p) =>
    p.expanded &&
    css`
      transform: scaleY(1);
      opacity: 1;
    `}

  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
      right: unset;
      left: 0;
    `}
  ${(props) => props.dropdownMenuCustomCss}
`;

const DropdownMenuInner = styled.div`
  border-radius: 5px;
  box-shadow: 0 37px 37px 0 rgba(21, 26, 47, 0.18);
  background-color: ${({ theme }) => theme.colors.white};
`;

const StyledLink = styled.a`
  display: flex;
  width: 100%;
  margin: 0;
  padding: 15px 20px;
  border-radius: 0;
  font-family: TTCommons;
  color: ${({ theme }) => theme.colors.black};
  font-size: 16px;
  text-align: left;
  transition: background-color 0.2s;
  text-decoration: none;
  cursor: pointer;
  align-items: center;
  &:hover {
    background-color: rgba(241, 244, 249, 1);
    color: ${({ theme }) => theme.colors.black};
  }
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      text-align: right;
    `}
`;

const StyledButton = styled.div`
  display: flex;
  width: 100%;
  margin: 0;
  padding: 15px 20px;
  border-radius: 0;
  font-family: TTCommons;
  color: ${({ theme }) => theme.colors.black};
  font-size: 16px;
  text-align: left;
  transition: background-color 0.2s;
  cursor: pointer;
  align-items: center;
  &:hover {
    background-color: rgba(241, 244, 249, 1);
    color: ${({ theme }) => theme.colors.black};
  }
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      text-align: right;
    `}
`;

const SvgContainer = styled.span`
  padding-right: 15px;
  line-height: 0;
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      padding-right: 0;
      padding-left: 15px;
    `}
`;

const Wrappper = styled(Flex)`
  position: relative;
`;

const Hiddenblock = styled.div`
  position: absolute;
  top: -999999999px;
  left: -999999999px;
`;

export default DownloadOptionsDropdown;
