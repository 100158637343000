import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';

import Flex from '/imports/core/ui/atoms/Flex';
import Logo from '/imports/core/ui/components/Logo';
import { useResponsive } from '/imports/core/api/responsiveContext';

const Header = () => {
  const { isMobile } = useResponsive();
  const isNewDesign = marvelEmitter.getActiveVariant('exp_rewrite_design_exp');
  const isCombineRWnApply = marvelEmitter.getActiveVariant('exp_combine_rw_and_apply') === 'with_combine_rw_and_apply';
  const NewDesignVar = ['design_2', 'design_3'];
  const ApplyNewDesign = NewDesignVar.includes(isNewDesign) && !isCombineRWnApply;

  return (
    <HeaderWrapper $isMobile={isMobile} id="success-header" $ApplyNewDesign={ApplyNewDesign}>
      <Container>
        <StyledFlex $justifyContent="space-between" $fullWidth>
          <HeaderFlex $alignItems="center">
            <Logo noLink />
          </HeaderFlex>
        </StyledFlex>
      </Container>
    </HeaderWrapper>
  );
};

const Container = styled.div`
  display: flex;
`;

const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  background: #fff;
  padding-inline: 20px;
  z-index: 1;
  box-shadow: 0 2px 4px 0 rgba(199, 207, 214, 0.2);
  position: relative;

  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      justify-content: end;
    `}

  ${({ $isMobile }) =>
    !$isMobile &&
    css`
      padding-block: 20px;
    `}

  ${({ theme }) => theme.max('md')`
    height: 64px;
    margin: 0;
  `}

  ${({ $ApplyNewDesign }) => css`
    ${$ApplyNewDesign &&
    css`
      @media (min-width: 981px) {
        box-shadow: unset;
        background: #f6f7fa;
      }
    `}
  `}
`;

const StyledFlex = styled(Flex)`
  ${({ theme }) => theme.max('md')`
    padding: 20px 0;
    align-items: center;
  `}
`;

const HeaderFlex = styled(Flex)`
  align-self: center;
`;

Header.propTypes = {
  isFullScreen: PropTypes.bool,
};

export default Header;
