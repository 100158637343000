import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelectStore } from 'zustand/SelectStore';
import useIntl from '/imports/core/api/useIntl';
import DropdownAutoSaveForFinalize from '/imports/core/ui/atoms/DropdownAutoSaveForFinalize';
import { blockDetailImmutableUpdate } from '/imports/generator/api/apollo/client/helpers';
import { UPDATE_RESUME_DETAIL } from '/imports/generator/api/apollo/client/mutations';
import FieldLabel from '/imports/generator/ui/atoms/FieldLabel';
import { DEFAULT_TEMPLATE_FONTS, TEMPLATES_FONTS, TEMPLATES_FONTS_ARABIK_EXP } from '/imports/pdf/core/api/constants';
import { getArabicDefaultFont } from '/imports/pdf/core/api/helpers';
import { GENERAL_FONTS_IMPORT } from '/styles/pdf-fonts/constants';

const FontSelector = ({
  font,
  path,
  source,
  source: { id, settings },
  updateImmue,
  language = '',
  withArabicExtraFonts,
}) => {
  const { t, locale } = useIntl();
  const WithTajwalfontvariant = locale === 'ar';
  const isATSResumev2 = marvelEmitter.getActiveVariant('exp_ats_resume_design') === 'with_new_design_v2';

  const label =
    path === 'headingFont' || path === 'arabicHeadingFont'
      ? t(`generator.settings.heading_font`)
      : t(`generator.settings.content_font`);
  const elementId =
    path === 'headingFont' || path === 'arabicHeadingFont'
      ? `generator-settings__heading_font`
      : `generator-settings__content_font`;

  const templateFonts = { ...TEMPLATES_FONTS, ...TEMPLATES_FONTS_ARABIK_EXP };
  let defaultFont = null;

  if (withArabicExtraFonts) {
    defaultFont = getArabicDefaultFont()?.[path];
  } else {
    defaultFont = DEFAULT_TEMPLATE_FONTS[settings.template]?.[path];
  }

  if (settings.template === 'atsresume' && isATSResumev2) {
    defaultFont = 'helvetica';
  }
  const fontsOptions = sortBy(
    Object.keys(templateFonts)
      .map((item) => ({
        value: item,
        title: `${templateFonts[item]?.fontName}${item === defaultFont ? ' (default)' : ''}`,
      }))
      .filter(
        ({ value }) =>
          WithTajwalfontvariant ||
          value !== 'tajawal' ||
          (!WithTajwalfontvariant && (settings?.headingFont === 'tajawal' || settings?.contentFont === 'tajawal')
            ? value
            : value !== 'tajawal'),
      ),
    'title',
  );

  const displayedFont = templateFonts?.hasOwnProperty(font) ? font : defaultFont;
  const { expanded } = useSelectStore();
  return (
    <Wrap language={settings.language}>
      <FieldLabel summary label={label} htmlFor={elementId} />
      <DropdownAutoSaveForFinalize
        mutation={UPDATE_RESUME_DETAIL}
        source={source}
        variables={{
          docId: id,
          path: `settings.${path}`,
          needUpdate: true,
        }}
        docType="resume"
        isExpanded={expanded === path}
        value={font}
        selected={displayedFont}
        isControlled={true}
        options={fontsOptions}
        isFontSelector={true}
        name={path}
        optimisticResponse={blockDetailImmutableUpdate(updateImmue)(id, `settings.${path}`)}
        id={elementId}
      />
    </Wrap>
  );
};

const Wrap = styled.div`
  position: relative;
  /* ${GENERAL_FONTS_IMPORT} */
`;

FontSelector.propTypes = {
  source: PropTypes.object,
  font: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};

export default FontSelector;
