import { Experiment as MarvelExperiment, Variant as MarvelVariant } from '@marvelapp/react-ab-test';
import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import _ from 'lodash';
import moment from 'moment';
import nookies from 'nookies';
import PropTypes from 'prop-types';
import qs from 'qs';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-apollo';
import { useDropzone } from 'react-dropzone';
import styled, { css, keyframes } from 'styled-components';
import { useCheckoutResumeStore } from 'zustand/CheckoutResumeStore';

import { getTokenCountGroup } from 'imports/generator/api/helpers';
import { useRouter } from 'next/router';
import { Push } from '/components/Link';
import env from '/env';
import { useAccount } from '/imports/core/api/accounts/accountContext';
import { useGeneralDesign } from '/imports/core/api/generalContext';
import { getBasicSettings } from '/imports/core/api/models/resumes/utils';
import { useResponsive } from '/imports/core/api/responsiveContext';
import intlHook from '/imports/core/api/useIntl';
import useWindowSize from '/imports/core/api/useWindowSize';
import useTracking from '/imports/core/hooks/useTracking';
import Flex from '/imports/core/ui/atoms/Flex';
import { CREATE_RESUME } from '/imports/dashboard/api/apollo/client/mutations';
import { parseBlockHeight } from '/imports/generator/api/api';
import { TEMPLATES } from '/imports/generator/api/constants';
import BlockRespositionProvider from '/imports/generator/context/blockresposition.context';
import Preview from '/imports/generator/ui/components/Preview';
import OneLineCss from '/imports/job-tracking/ui/atoms/OneLineCss';
import Container from '/imports/resume-options/atoms/Container';
import BackIcon from '/imports/resume-options/ui/assest/BackIcon';
import CloseIcon from '/imports/resume-options/ui/assest/CloseIcon';
import SuccessIcon from '/imports/resume-options/ui/assest/SuccessIcon';
import UploadResumeAutoApply from '/imports/resume-options/ui/component/UploadResumeAutoApply';
import {
  ATS_WITH_PHOTO_TEMPLATE_WON_LOCALE_DESKTOP,
  ATS_WITH_PHOTO_TEMPLATE_WON_LOCALE_MOBILE,
  ATS_WITHOUT_PHOTO_TEMPLATE_WON_LOCALE_DESKTOP,
  ATS_WITHOUT_PHOTO_TEMPLATE_WON_LOCALE_MOBILE,
  cdnFile,
  cdnTemplateImage,
  cookieParser,
  customTemplateImage,
  getActiveVariantStorage,
  getPreviewBlocksHeight,
  isFontUpdate,
  removeExperiment,
  RESUME_BULLET_EXP_VARIANTS_DESKTOP,
  staticFile,
} from '/lib/helpers';

const UploadResume = ({
  template,
  setPreviousStep,
  title = '',
  uploadModal = false,
  onClose,
  setLoading,
  loading,
  resume,
  step,
}) => {
  const router = useRouter();
  const isAutoApply = router.query.isFromAutoApply === 'true';
  const inputFile = useRef(null);
  const { locale, t, resumeLocale } = intlHook();
  const { token } = cookieParser();
  const { trackEvent } = useTracking();
  const [file, setFile] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [createResume] = useMutation(CREATE_RESUME);
  const { width, height } = useWindowSize();
  const [heightResume, setHeightResume] = useState(null);
  const [flag, setFlag] = useState(false);
  const { currentUser } = useAccount();
  const generalData = useGeneralDesign();
  const { deviceInfo, isMobile } = useResponsive();
  const activeVar = 'with_bullet';
  const cookieData = nookies.get({});
  const withNewFlow = false;
  const { defaultColor, ...templateRest } = TEMPLATES.find((t) => t.id === template);
  const { user_id, agent_client_id } = nookies.get({});
  const IsAutoRepositoring = marvelEmitter.getActiveVariant('exp_block_repositing') === 'with_complete_repositioning';
  const isNewATSResume = marvelEmitter.getActiveVariant('exp_ats_resume_design') === 'with_new_design_v2';

  const { setResume } = useCheckoutResumeStore();
  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0]);
  }, []);
  useEffect(() => {
    if (uploadModal) trackEvent('resume_upload_modal_open');
    removeExperiment('resume_upload_lang_v2');
    removeExperiment('resume_lang_detect_with_gpt_v2');
    removeExperiment('exp_affinda_vs_gpt');
    removeExperiment('exp_resume_bullets_v3');
    removeExperiment('exp_resume_parse');
    removeExperiment('exp_resume_parse_v2');

    trackEvent('resume_upload_view');
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc'],
      'text/plain': ['.txt'],
      'text/html': ['.html'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'application/vnd.oasis.opendocument.text': ['.odt'],
      'application/rtf': ['.rtf'],
    },
  });

  const getIcon = (fileType) => {
    const type = fileType.split('/')[1];
    switch (type) {
      case 'pdf':
        return 'img/resume-options/pdfIcon.png';
      case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
        return 'img/resume-options/docxIcon.png';
      case 'msword':
        return 'img/resume-options/docIcon.png';
      case 'plain':
        return 'img/resume-options/txtIcon.png';
      case 'html':
        return 'img/resume-options/htmlIcon.png';
      case 'rtf':
        return 'img/resume-options/rtfIcon.png';
      case 'vnd.oasis.opendocument.text':
        return 'img/resume-options/odtIcon.png';
      default:
        return 'img/resume-options/pdfIcon.png';
    }
  };

  const handleNext = async () => {
    const websiteLocale = locale;
    const currentTemplate = templateRest?.redirectTemplate || template;
    if (!file) {
      const { data } = await createResume({
        variables: getBasicSettings(currentTemplate, resumeLocale || locale, websiteLocale, isNewATSResume),
      });

      if (data.createResume) {
        const queryString = {
          resumeId: data.createResume.id,
          step: 'start',
          template: currentTemplate,
          userId: currentUser?.id,
        };
        return Push(
          `/resume?${qs.stringify(queryString)}`,
          locale,
          `/resume/${data.createResume.id}/start?userId=${currentUser?.id}`,
        );
      }
    }
    const obj = {};
    trackEvent('existing_resume_upload', obj);
    trackEvent('resume_start_upload_cta', { upload_from: 'resume_upload_view' });
    setLoading(true);
    var formData = new FormData();
    formData.append('deviceType', deviceInfo.type);

    if (uploadModal) {
      formData.append('resumeId', resume.id);
      formData.append('file', file);
      formData.append('resumeCurrentStep', step);
    } else {
      formData.append('file', file);
      formData.append('template', currentTemplate);
      formData.append('color', defaultColor);
      formData.append('language', locale);
    }
    formData.append('variant', activeVar);
    const { indicative_unique_id } = cookieData;
    if (indicative_unique_id) {
      formData.append('eventUniqueId', indicative_unique_id);
    }
    const withArabicFont = locale === 'ar';
    if (withArabicFont) {
      formData.append('headingFont', 'tajawal');
      formData.append('contentFont', 'tajawal');
    }
    const updatedFont = isFontUpdate(locale);
    if (updatedFont) {
      formData.append('headingFont', 'robotoMono');
    }
    if (isNewATSResume && currentTemplate === 'atsresume') {
      formData.append('headingFont', 'helvetica');
      formData.append('contentFont', 'helvetica');
    }
    try {
      let headers = {
        Authorization: 'Bearer ' + token,
      };

      if (user_id) {
        headers['x-user-id'] = user_id;
      }
      if (agent_client_id) {
        headers['x-user-id'] = agent_client_id;
      }

      let resp = await fetch(`${env.BASE_API}/resume/parse-resume`, {
        method: 'POST',
        headers: headers,
        body: formData,
      });
      let response = await resp.json();
      if (response?.aiTokenCounts) {
        trackEvent('resume_token_count', {
          inputToken: getTokenCountGroup(response?.aiTokenCounts?.inputTokenCount - 564),
          outputToken: getTokenCountGroup(response?.aiTokenCounts?.outputTokenCount),
        });
      }
      setHeightResume(response);
      withNewFlow && localStorage && localStorage.setItem('rsid', response.resumeId);
      setFlag(true);
      setIsSuccess(true);
      if (uploadModal) {
        localStorage.setItem('resume-parse', 'true');
        generalData.refetch();
        setLoading(false);
        return onClose();
      }
    } catch (e) {
      trackEvent('failed_resume_parse', { from_view: 'resume_upload_view' });
      setLoading(false);
      uploadModal ? onClose() : setPreviousStep();
      console.log('This e', e);
    }
  };

  useEffect(() => {
    if (heightResume && !flag) {
      if (heightResume?.resumeId) {
        if (isAutoApply) {
          Push(`/apply-form?resumeId=${heightResume?.resumeId}&userId=${heightResume?.userId}`, locale);
        } else {
          const queryString = {
            resumeId: heightResume.resumeId,
            step: 'start',
            template: template,
            userId: currentUser?.id,
          };
          if (IsAutoRepositoring) {
            queryString.optimizedResume = true;
          }
          const url = `/resume/${heightResume.resumeId}/start?${qs.stringify({
            userId: currentUser?.id,
            ...(IsAutoRepositoring && { optimizedResume: true }),
          })}`;
          Push(`/resume?${qs.stringify(queryString)}`, locale, url);
        }
      }
    }
  }, [flag]);

  const modelTitle = title ? t(title) : t('resume_options_title_4');

  const handleClose = () => (file ? setFile(null) : uploadModal ? onClose() : setPreviousStep());

  const applyBlockHeight = async (heights) => {
    const resp = await parseBlockHeight(heights, heightResume.resumeId, token, agent_client_id, user_id);
    if (resp) {
      setTimeout(() => {
        setFlag(false);
      }, 500);
    }
    trackEvent('upload_resume_block_respositioning');
  };

  const prevRender = () => {
    const blocks = heightResume.blocks;
    const customBlocks =
      blocks && blocks.length > 0 ? blocks.map((b) => (b.type === 'CUSTOM' ? b.id : null)).filter((b) => b) : [];
    const customSkillsBlocks =
      blocks && blocks.length > 0
        ? blocks.map((b) => (b.type === 'CUSTOM_SKILLS_CATEGORY' ? b.id : null)).filter((b) => b)
        : [];
    const heights = getPreviewBlocksHeight(customBlocks, customSkillsBlocks);
    applyBlockHeight(heights);
  };

  const getCustomImage = (template) => {
    const imagePath = customTemplateImage?.[template]?.[resumeLocale]
      ? customTemplateImage?.[template]?.[resumeLocale]
      : customTemplateImage?.[template]?.default;
    return imagePath;
  };

  if (isAutoApply)
    return (
      <UploadResumeAutoApply
        file={file}
        setFile={setFile}
        loading={loading}
        uploadModal={uploadModal}
        isAutoApply={isAutoApply}
        handleNext={handleNext}
        heightResume={heightResume}
        inputfileref={inputFile}
        getInputProps={getInputProps}
        getRootProps={getRootProps}
        onClose={handleClose}
        prevRender={prevRender}
        isSuccess={isSuccess}
      />
    );

  return (
    <Main uploadModal={uploadModal}>
      <Wrapper
        fullWidth
        alignItems="center"
        justifyContent="center"
        direction="column"
        loading={loading}
        uploadModal={uploadModal}
      >
        <TitleFlex uploadModal={uploadModal}>
          <Title dangerouslySetInnerHTML={{ __html: modelTitle }} uploadModal={uploadModal} />
          {uploadModal && (
            <SvgWrapper onClick={onClose}>
              <CloseIcon />
            </SvgWrapper>
          )}
        </TitleFlex>
        {!file ? (
          <Upload
            onClick={() => inputFile.current?.click()}
            justifyContent="center"
            alignItems="center"
            direction="column"
            {...getRootProps({ className: 'dropzone' })}
            multiple={false}
            uploadModal={uploadModal}
          >
            <Input type="File" ref={inputFile} className="input-zone" accept=".pdf" {...getInputProps()} />
            <Icon />
            <StyledFlex direction="column" alignItems="center">
              <Heading>{t('resume_options_sub_title_3')}</Heading>
              <SubHeading>{t('resume_options_sub_title_4')}</SubHeading>
            </StyledFlex>
          </Upload>
        ) : (
          <LoaderWrap fullWidth alignItems="center" justifyContent="center" uploadModal={uploadModal}>
            <PdfNameWrapper fullWidth alignItems="center" uploadModal={uploadModal}>
              <FileIcon icon={getIcon(file.type)} />
              <PdfFlex direction="column" fullWidth uploadModal={uploadModal}>
                <PdfName uploadModal={uploadModal} isMobile={isMobile}>
                  {file?.name}
                </PdfName>
                <UploadedDate>
                  {t('resume_options_upload_modal')} {moment(new Date()).format('DD/MM/YYYY')}
                </UploadedDate>
              </PdfFlex>
            </PdfNameWrapper>
            <Close onClick={() => setFile(null)}>
              <CloseIcon />
            </Close>
          </LoaderWrap>
        )}
        <StyledButton justifyContent="space-between" fullWidth alignItems="center">
          <Back onClick={handleClose} alignItems="center">
            <BackIconWrapper>
              <BackIcon />
            </BackIconWrapper>
            {t('resume_options_btn_back')}
          </Back>
          {file && (
            <Next onClick={handleNext} disabled={loading}>
              {loading ? t('payment.loading') : t('resume_options_btn_next')}
            </Next>
          )}
        </StyledButton>
      </Wrapper>
      {loading && (
        <LoadingTemplate justifyContent="center" alignItems="center" fullWidth uploadModal={uploadModal}>
          <Resume
            template={template}
            locale={resumeLocale}
            $customImage={getCustomImage(template)}
            $atsresume={template === 'atsresume'}
            isMobile={isMobile}
          />
          <Temp>
            {isSuccess ? (
              <SuccessWrap alignItems="center" justifyContent="center">
                <SuccessIcon />
              </SuccessWrap>
            ) : (
              <Content direction="column" justifyContent="center" alignItems="center">
                <LoadingText>{t('resume_options_loading_state')}</LoadingText>
                <Bar>
                  <Loader />
                </Bar>
              </Content>
            )}
          </Temp>
        </LoadingTemplate>
      )}
      {heightResume && (
        <HiddenblockLayOut resume={heightResume} width={width} height={height} prevRender={prevRender} nocloseIcon />
      )}
    </Main>
  );
};

const RenderUpload = (props) => {
  const { isMobile } = useResponsive();
  if (isMobile) return <UploadResume {...props} />;
  const variants = RESUME_BULLET_EXP_VARIANTS_DESKTOP;
  marvelEmitter.defineVariants('exp_resume_bullets_v3', variants);
  if (typeof localStorage !== 'undefined') {
    const current = getActiveVariantStorage('exp_resume_bullets_v3');
    const reset = current && !variants.includes(current);
    reset && marvelEmitter.setActiveVariant('exp_resume_bullets_v3', _.sample(variants));
  }
  return (
    <MarvelExperiment name="exp_resume_bullets_v3">
      {variants.map((variant) => (
        <MarvelVariant key={variant} name={variant}>
          <UploadResume {...props} />
        </MarvelVariant>
      ))}
    </MarvelExperiment>
  );
};

export default RenderUpload;

UploadResume.propTypes = {
  template: PropTypes.string,
  setPreviousStep: PropTypes.func,
  title: PropTypes.string,
  uploadModal: PropTypes.bool,
  onClose: PropTypes.func,
  setLoading: PropTypes.func,
  loading: PropTypes.bool,
  resume: PropTypes.object,
  step: PropTypes.string,
};

export const HiddenblockLayOut = ({ resume, width, height, prevRender, nocloseIcon }) => {
  useEffect(() => {
    setTimeout(() => {
      prevRender();
    }, 500);
  }, []);

  return (
    <Hiddenblock>
      <BlockRespositionProvider>
        <Preview
          resume={resume}
          updatesCount={resume ? resume.updatesCount : null}
          width={width}
          height={height}
          nocloseIcon={nocloseIcon}
        />
      </BlockRespositionProvider>
    </Hiddenblock>
  );
};

HiddenblockLayOut.propTypes = {
  resume: PropTypes.object,
  width: PropTypes.string,
  height: PropTypes.string,
  prevRender: PropTypes.func,
  nocloseIcon: PropTypes.bool,
};

const Hiddenblock = styled.div`
  position: absolute;
  top: -99999999px;
`;

const TitleFlex = styled(Flex)`
  ${({ uploadModal }) =>
    uploadModal &&
    css`
      width: 100%;
      justify-content: center;
      @media (min-width: 768px) {
        justify-content: space-between;
      }
    `}
`;

const SvgWrapper = styled.div`
  cursor: pointer;
  svg {
    path {
      fill: #000;
    }
  }
  display: none;

  @media (min-width: 768px) {
    display: flex;
  }
`;

const PdfNameWrapper = styled(Flex)`
  gap: 10px;
  ${({ uploadModal, theme }) =>
    uploadModal &&
    css`
      ${theme.max('xs')`
      gap:5px;
    `}
    `}
`;

const SuccessWrap = styled(Flex)`
  height: 100%;
`;

export const Progress = keyframes`
  0% {
    width: 0%;
  }
  75% {
    width: 75%;
  }
  100% {
    width: 75%;
  }
`;

const Loader = styled.div`
  background-color: #1688fe;
  height: 4px;
  animation: ${Progress} 10s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      margin-left: auto;
      margin-right: 0;
    `}
`;

const Bar = styled.div`
  height: 4px;
  background-color: #e7f3fe;
  width: 90%;
  margin-top: 16px;
  opacity: 1;
`;

const LoadingText = styled.div`
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 16px;
  line-height: 19px;
  color: #1688fe;
`;

const Content = styled(Flex)`
  height: 100%;
`;

const Temp = styled.div`
  height: 439px;
  width: 300px;
  background-color: var(--light-values-white);
  position: absolute;
  opacity: 0.8;
  border-radius: 16px;
`;

const Resume = styled(Flex)`
  ${({ template }) =>
    template !== 'atsresume' &&
    template &&
    css`
      background-image: url(${cdnFile(`resumes/${template}.jpg`)});
    `}
  ${({ locale, template }) =>
    template &&
    locale !== 'en' &&
    css`
      background-image: url(${staticFile(`img/resume/${locale}/${template}.jpg`)});
    `}

  ${({ $customImage }) =>
    $customImage &&
    css`
      background-image: url(${staticFile($customImage)});
    `}

${({ $atsresume, isMobile, locale }) => {
    if (!$atsresume) return null;

    const isWithoutPhoto =
      (ATS_WITHOUT_PHOTO_TEMPLATE_WON_LOCALE_DESKTOP.includes(locale) && !isMobile) ||
      (ATS_WITHOUT_PHOTO_TEMPLATE_WON_LOCALE_MOBILE.includes(locale) && isMobile);

    const isWithPhoto =
      (ATS_WITH_PHOTO_TEMPLATE_WON_LOCALE_DESKTOP.includes(locale) && !isMobile) ||
      (ATS_WITH_PHOTO_TEMPLATE_WON_LOCALE_MOBILE.includes(locale) && isMobile);

    const isWithoutPhotoVar = marvelEmitter.getActiveVariant('exp_ats_preview_v2') === 'without_photo';

    const backgroundImage = isWithoutPhoto
      ? staticFile(`img/resume/${locale}/ATS.jpg`)
      : isWithPhoto
        ? staticFile(`img/resume/${locale}/ATSWithImage.jpg`)
        : isWithoutPhotoVar
          ? staticFile(`img/resume/${locale}/ATS.jpg`)
          : staticFile(`img/resume/${locale}/ATSWithImage.jpg`);

    return css`
      background-image: url(${backgroundImage});
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    `;
  }}


    ${({ template, locale }) =>
    (template === 'cali' || template === 'lima') &&
    template &&
    css`
      background-image: url(${cdnTemplateImage(`resume-images/resume/${locale}/${template}.jpg`)});
    `}

  height: 439px;
  width: 300px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 16px;
  opacity: 0.95;
`;

const LoadingTemplate = styled(Flex)`
  min-height: 100vh;
  ${({ uploadModal }) =>
    uploadModal &&
    css`
      min-height: 100%;
    `}
`;

const StyledButton = styled(Flex)`
  width: 90%;
  height: 48px;
  @media (min-width: 768px) {
    width: 620px;
  }
  ${({ theme }) =>
    theme.isRTL &&
    css`
      direction: rtl;
    `}
`;

const Close = styled(Flex)`
  margin-left: auto;
  cursor: pointer;
  ${({ theme }) =>
    theme.isRTL &&
    css`
      margin: 0;
      margin-right: auto;
    `}
`;

const Back = styled(Flex)`
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 16px;
  line-height: 24px;
  color: #7171a6;
  cursor: pointer;
  gap: 12px;
`;
const BackIconWrapper = styled(Flex)`
  ${({ theme }) =>
    theme.isRTL &&
    css`
      transform: rotate(180deg);
    `}
`;

const Next = styled.div`
  padding: 12px 30px;
  background: #1688fe;
  border-radius: 4px;
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  color: #ffffff;
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
  &:hover {
    background: #0b78e8;
  }
`;

const UploadedDate = styled.div`
  font-family: ${({ theme }) => theme.font.family.websiteRegular};
  font-size: 13px;
  line-height: 15px;
  color: #7171a6;
`;

const PdfName = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: #1688fe;
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  ${({ uploadModal }) =>
    uploadModal &&
    css`
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      max-width: 100px;
      @media (min-width: 768px) {
        max-width: none;
      }
    `}

  ${({ isMobile }) =>
    isMobile &&
    css`
      width: 75%;
      ${OneLineCss}
    `}
`;

const PdfFlex = styled(Flex)`
  margin-left: 10px;
  gap: 5px;
  ${({ uploadModal, theme }) =>
    uploadModal &&
    css`
      ${theme.max('xs')`
        margin: 0;
      `}
    `}
`;

const FileIcon = styled(Flex)`
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  ${({ icon }) =>
    icon &&
    css`
      background-image: url(${staticFile(icon)});
    `}
  background-size: contain;
  background-repeat: no-repeat;
`;

const LoaderWrap = styled(Flex)`
  background: #ffffff;
  border: 2px solid #1688fe;
  border-radius: 3px;
  padding: 24px;
  height: 120px;
  width: 90%;
  align-items: center;
  @media (min-width: 768px) {
    width: 620px;
  }
  ${({ loading }) =>
    loading &&
    css`
      display: none;
    `}
  ${({ theme }) =>
    theme.isRTL &&
    css`
      direction: rtl;
    `}
  ${({ uploadModal }) =>
    uploadModal &&
    css`
      ${({ theme }) => theme.max('xs')`
      width: 100%;
      padding:12px;
    `}
    `}
`;

const StyledFlex = styled(Flex)`
  gap: 10px;
`;

const SubHeading = styled.div`
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  color: #7171a6;
`;

const Heading = styled.div`
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #33334f;
`;

const Icon = styled.div`
  background-image: url(${staticFile('img/resume-options/FolderIcon.png')});
  height: 74px;
  width: 74px;
  background-size: cover;
`;

const Upload = styled(Flex)`
  gap: 10px;
  width: 90%;
  height: 188px;
  background: #ffffff;
  border: 2px dashed #1688fe;
  border-radius: 3px;
  cursor: pointer;
  padding: 16px;
  @media (min-width: 768px) {
    width: 620px;
  }
  @media (min-width: 992px) {
    width: 620px;
  }
  ${({ uploadModal }) =>
    uploadModal &&
    css`
      @media (min-width: 768px) {
        width: 100%;
      }
    `}
`;

const Input = styled.input`
  display: none;
`;

const Wrapper = styled(Flex)`
  gap: 32px;
  ${({ loading }) =>
    loading &&
    css`
      display: none;
    `}
  ${({ uploadModal }) =>
    uploadModal &&
    css`
      margin-top: 0;
      align-items: center;
      magrin: auto;
      height: 100%;
    `}
@media(min-width: 768px) {
    gap: 0;
    > div:not(:last-child) {
      margin-bottom: 32px;
    }
  }
`;

const Title = styled.div`
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 50px;
  line-height: 60px;
  letter-spacing: -0.005em;
  color: #33334f;
  text-align: center;
  span {
    color: #1688fe;
  }
  ${({ uploadModal }) =>
    uploadModal &&
    css`
      font-size: 25px;
      line-height: 30px;
      @media (min-width: 768px) {
        font-size: 50px;
        line-height: 60px;
      }
    `}
`;

const Main = styled(Container)`
  min-height: 100vh;
  align-items: center;
  ${({ uploadModal }) =>
    uploadModal &&
    css`
      min-height: 100%;
      max-width: 100%;
      min-width: 100%;
      margin: 0;
    `}
`;
